<script lang="ts">
  import Header from "./Components/Header/Header.svelte";
  import { activeTheme } from "./stores/themeStore";
</script>

<main
  style="
    --color1: {$activeTheme.colors.color1};
    --color2: {$activeTheme.colors.color2};
    --color3: {$activeTheme.colors.color3};
    --color4: {$activeTheme.colors.color4};
    --color5: {$activeTheme.colors.color5};"
>
  <Header />
  <p>
    Visit the <a href="https://svelte.dev/tutorial">Svelte tutorial</a> to learn
    how to build Svelte apps.
  </p>
</main>

<style>
  main {
    transition: color 3s ease-in-out;
    background-color: var(--color3);
    height: 100%;
    width: 100%;
    color: var(--color2);
  }
  :global(body) {
    padding: 0;
    margin: 0;
  }
</style>
