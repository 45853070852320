<script lang="ts">
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { Palette, CaretDown } from "phosphor-svelte";
  import ThemePreview from "./ThemePreview.svelte";
  import ThemeInput from "./ThemeInput.svelte";
  import { activeTheme } from "../../stores/themeStore";

  let displayThemeConfig = false;
  const toggleThemeConfig = () => (displayThemeConfig = !displayThemeConfig);
</script>

<div>
  <button
    class={`btn palette ${displayThemeConfig && "active"}`}
    on:click={toggleThemeConfig}
    ><CaretDown size={32} /><Palette size={32} weight="fill" /></button
  >
  {#if displayThemeConfig}
    <div
      class="container"
      transition:slide={{ duration: 300, easing: quintOut }}
    >
      <ThemePreview themeName="theme1" />
      <ThemePreview themeName="theme2" />
      <ThemePreview themeName="theme3" />

      {#if $activeTheme.name === "theme4"}
        <ThemeInput />
      {:else}
        <ThemePreview themeName="theme4" />
      {/if}
    </div>
  {/if}
</div>

<style>
  .container {
    height: 240px;
    margin-top: 50px;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .relative-container {
    position: relative;
  }
  .btn {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: var(--color1);
  }

  .btn:active {
    background: none;
  }
  .active {
    color: var(--color2);
  }

  .palette {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }
</style>
