import { writable } from "svelte/store";
import { themes } from "../config/theme";
const initTheme = () => {
    const initialThemeCopy = Object.assign({}, themes.theme1);
    const { subscribe, set, update } = writable(initialThemeCopy);
    return {
        subscribe,
        set,
        setTheme: (themeName) => update(() => themes[themeName]),
    };
};
export const activeTheme = initTheme();
