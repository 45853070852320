<script>
  import ScratchSvg from "./ScratchSvg.svelte";
</script>

<main>
  <div class="wrapper">
    <div class="abs">
      <ScratchSvg />
    </div>
    <h1 class="abs text">edveloper</h1>
  </div>
</main>

<style>
  .wrapper {
    width: 150px;
    height: 150px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .abs {
    position: absolute;
  }
  .text {
    color: var(--color3);
  }
</style>
