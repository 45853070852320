export const themes = {
    theme1: {
        name: "theme1",
        colors: {
            color1: "#f6f6f2",
            color2: "#fe6c27",
            color3: "#1b1d3f",
            color4: "#521114",
            color5: "#0c0b03",
        },
    },
    theme2: {
        name: "theme2",
        colors: {
            color1: "#a14658",
            color2: "#f95c6f",
            color3: "#f9a2b5",
            color4: "#fbd2dd",
            color5: "#f6f6f2",
        },
    },
    theme3: {
        name: "theme3",
        colors: {
            color1: "#fba200",
            color2: "#fe6c27",
            color3: "#911952",
            color4: "#cd2b69",
            color5: "#f25980",
        },
    },
    theme4: {
        name: "theme4",
        colors: {
            color1: "#a14658",
            color2: "#f95c6f",
            color3: "#f9a2b5",
            color4: "#fbd2dd",
            color5: "#f6f6f2",
        },
    },
};
