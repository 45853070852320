<script lang="ts">
  import { Eyedropper } from "phosphor-svelte";

  import { themes } from "../../config/theme";
  import { activeTheme } from "../../stores/themeStore";

  export let themeName: keyof Themes;
  const isCustomTheme = themeName === "theme4";
</script>

<button class="btn" on:click={() => activeTheme.setTheme(themeName)}>
  <div class:rel={isCustomTheme}>
    <div
      class:abs={isCustomTheme}
      class="theme-preview {$activeTheme.name === themeName && 'active'}"
    >
      <div
        class="color-preview left"
        style="background-color: {themes[themeName].colors.color1}"
      />
      <div
        class="color-preview"
        style="background-color: {themes[themeName].colors.color2}"
      />
      <div
        class="color-preview"
        style="background-color: {themes[themeName].colors.color3}"
      />
      <div
        class="color-preview"
        style="background-color: {themes[themeName].colors.color4}"
      />
      <div
        class="color-preview right"
        style="background-color: {themes[themeName].colors.color5}"
      />
    </div>
    {#if isCustomTheme}
      <div class="custom-overlay">
        <Eyedropper size={48} weight="fill" />
      </div>
    {/if}
  </div>
</button>

<style>
  .rel {
    position: relative;
  }
  .abs {
    position: absolute;
  }
  .left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .color-preview {
    height: 50px;
    width: 30px;
  }

  .active {
    border: 3px solid var(--color1) !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .theme-preview {
    display: flex;
    border: 3px solid transparent;
  }

  .theme-preview:hover {
    border: 3px solid var(--color1) !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .custom-overlay:hover {
    border: 3px solid var(--color1) !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .btn {
    border: none;
    background: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: var(--color1);
  }

  .btn:active {
    background: none;
  }

  .custom-overlay {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
  }
</style>
