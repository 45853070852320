<script>
  import Logo from "./Logo.svelte";
  import ThemeConfig from "../ThemeConfig/ThemeConfig.svelte";
</script>

<main>
  <div class="container">
    <Logo />
    <div class="config">
      <ThemeConfig />
    </div>
  </div>
</main>

<style>
  .container {
    background-color: var(--color3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .config {
    right: 10px;
    position: absolute;
    top: 10px;
  }
</style>
