<script lang="ts">
  import { Eyedropper } from "phosphor-svelte";

  import { activeTheme } from "../../stores/themeStore";
</script>

<main>
  <button
    class="btn theme-preview {$activeTheme.name === 'theme4' && 'active'}"
    on:click={() => activeTheme.setTheme("theme4")}
  >
    <input
      type="color"
      bind:value={$activeTheme.colors.color1}
      class="color-input left"
      style="background-color: {$activeTheme.colors.color1}"
    />
    <input
      type="color"
      bind:value={$activeTheme.colors.color2}
      class="color-input"
      style="background-color: {$activeTheme.colors.color2}"
    />
    <input
      type="color"
      bind:value={$activeTheme.colors.color3}
      class="color-input"
      style="background-color: {$activeTheme.colors.color3}"
    />
    <input
      type="color"
      bind:value={$activeTheme.colors.color4}
      class="color-input"
      style="background-color: {$activeTheme.colors.color4}"
    />
    <div class="rel">
      <input
        type="color"
        bind:value={$activeTheme.colors.color5}
        class="color-input right abs"
        style="background-color: {$activeTheme.colors.color5}"
      />
      <div class="abs">
        <Eyedropper size={24} weight="fill" />
      </div>
    </div>
  </button>
</main>

<style>
  .rel {
    position: relative;
  }
  .abs {
    position: absolute;
  }
  .left {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  .right {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  .color-input {
    height: 50px;
    width: 30px;
    outline: none;
    padding: 0;
    border: none;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
  }

  .color-input::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  .color-input::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }

  .active {
    border: 3px solid var(--color1) !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .theme-preview {
    display: flex;
  }

  .btn {
    border: none;
    background: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: var(--color1);
  }

  .btn:active {
    background: none;
  }
</style>
